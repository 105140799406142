<template>
  <div class="container_home">
    <div class="img">
      <img src="../../assets/text_home.png" alt="" class="home_img" />
    </div>
    <div class="btn_box">
      <div class="btn_enter" @click="enter">进入渠道管理</div>
      
    </div>
    <!-- <div class="header">
      <div class="header-name">
        <img class="avator" src="../../assets/menu_01.png" alt="">
        <span class="regret" v-if="userInfo">早安，{{userInfo.realname}}，祝你开心每一天！</span>
      </div>
    </div>
    <div class="content">
      <div class="content-left">
        <div class="cl-one">
          <div class="clo-left common">
            <div v-if="wallet">
              <div class="clol-name">账户余额</div>
              <div class="extra">￥{{wallet.total}}</div>
              <div class="clol-ky"><span class="span">可用余额</span><span>￥{{wallet.normalBalanceAmount}}</span></div>
              <div class="clol-ky"><span class="span">不可用余额</span><span>￥{{wallet.frozenBalanceAmount}}</span></div>
            </div>
            <div><a-button type="primary" @click="charge">充值</a-button></div>
            <a-modal
              title="汇款信息"
              :visible="visiblehk"
              :footer="null"
              @cancel="visiblehk=false"
              width="700px"
              v-if="visiblehk"
            >
              <div v-if="bankInfo">
                <div class="hkxx-header"><a-icon theme="filled" style="color:#1890ff;margin-right:4px" type="info-circle" />温馨提示：请线下汇款，汇款时间在工作日9：00-16：30之间，预计2小时内到账，请注意查收到账短信或邮件。</div>
                <ul class="hkxx-content">
                  <li>
                    <span class="left">付款账户：</span>
                    <span class="right">{{bankInfo.payEnterpriseName}}</span>
                  </li>
                  <li>
                    <span class="left">收款账户名：</span>
                    <span class="right">{{bankInfo.bankAccountName}}</span>
                  </li>
                  <li>
                    <span class="left">开户银行：</span>
                    <span class="right">{{bankInfo.bankName}}</span>
                  </li>
                  <li>
                    <span class="left">银行卡号：</span>
                    <span class="right">{{bankInfo.bankNo}}</span>
                  </li>
                </ul>
              </div>
            </a-modal>
          </div>
          <div class="clo-right common">
            <div class="clor-name">代办事项</div>
            <div class="clor-ct">
              <div class="colr-blc">
                <div class="clor-title">待开票金额</div>
                <h1 class="clor-account" v-if="awaitPay">{{awaitPay}}</h1>
              </div>
              <div class="colr-blc">
                <div class="clor-title">待支付订单</div>
                <h1 class="clor-account clo-red" v-if="tradeInfo">{{tradeInfo.payCount}}</h1>
              </div>
              <div class="colr-blc">
                <div class="clor-title">待审核订单</div>
                <h1 class="clor-account" v-if="tradeInfo">{{tradeInfo.auditCount}}</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="cl-two common">
          <h2 class="h2">我的导航</h2>
          <ul class="clt-ul">
            <li v-for="(item,index) in urlList" :key="index"><a @click="ggMenu(item.url)" :href="item.url" target="_blink">{{item.name}}</a></li>
          </ul>
        </div>
        <div class="cl-three common">
          <h2 class="h2">业务概览</h2>
          <div class="mychart">
            <div id="myChart"></div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="cr-one common">
          <h2 class="h2">订单数据</h2>
          <ul class="cro-ul">
            <li>
              <p class="crou-title">充值总金额</p>
              <div class="crou-count" v-if="rechargeInfo">{{rechargeInfo.totalRechargeAmount}}</div>
            </li>
            <li>
              <p class="crou-title">今日充值</p>
              <div class="crou-count" v-if="rechargeInfo">{{rechargeInfo.dayRechargeAmount||0}}</div>
            </li>
            <li v-if="payInfo">
              <p class="crou-title">累计支付金额</p>
              <div class="crou-count">{{payInfo.amount}}</div>
            </li>
            <li v-if="payInfo">
              <p class="crou-title">累计支付订单(笔)</p>
              <div class="crou-count">{{payInfo.num}}</div>
            </li>
          </ul>
        </div>
        <div class="cr-two common">
          <ul class="crt-o" v-if="enterpriseInfo">
            <li>商户信息</li>
            <li>商户简称：{{enterpriseInfo.brieflyNme}}</li>
            <li>认证名称：{{enterpriseInfo.authNme}} </li>
            <li>预留联系人：{{enterpriseInfo.contacts}} </li>
            <li>预留电话：{{enterpriseInfo.contactsPhone}}</li>
          </ul>
          <div class="line"></div>
          <ul class="crt-o">
            <li>智赋云结算公众号</li>
            <li>关注智赋云获取最新动态<br/>随时随地与智赋云高效管理</li>
          </ul>
          <div>
            <img style="margin-top:20px;width:117px;height:127px" src="../../assets/home_erwei.png" alt="">
          </div>
        </div>
        <div class="cr-three common">
          <div class="crt-header">
            <span class="crth-l">系统公告</span>
            <span class="crth-r" @click="checkAll">查看全部</span>
          </div>
          <ul class="crt-ul">
            <li v-for="(item,index) in noticeLists" :key="index" @click="showGk(item)"><a>【公告】{{item.title}}</a></li>
          </ul>
          <a-modal
              title="公告"
              :visible="visiblegk"
              :footer="null"
              @cancel="visiblegk=false"
              width="700px"
              v-if="visiblegk"
            >
              <a-textarea :value="content.content" class="textarea" autoSize="true" readonly></a-textarea>
            </a-modal>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import Vue from "vue";
import { ACCESS_TOKEN, USER_INFO } from "@/store/mutation-types";
import {
  getEnterpriseReserveInfo,
  getPayStatistics,
  getWalletByType,
  getInvoiceTradesAmount,
  getBanknum,
  recharge,
  tradeCount,
  notice,
  getLineChart,
} from "@/api/api";
export default {
  data() {
    return {
      userInfo: null,
      enterpriseInfo: null,
      payInfo: null,
      wallet: null,
      awaitPay: null,
      bankInfo: null,
      rechargeInfo: null,
      tradeInfo: null,
      visiblehk: false,
      noticeLists: [],
      visiblegk: false,
      content: null,
      token: "",
      urlList: [
        {
          url: window._CONFIG["fenshilingyingUlr"] + "raskManage?token=",
          name: "任务管理",
          menu: "raskManage",
        },
        {
          url: window._CONFIG["fenshilingyingUlr"] + "settlementManage?token=",
          name: "付款管理",
          menu: "settlementManage",
        },
        {
          url: window._CONFIG["fenshilingyingUlr"] + "kkfp?token=",
          name: "可开发票",
          menu: "kkfp",
        },
      ],
    };
  },
  created() {
    this.token = Vue.ls.get(ACCESS_TOKEN);
    this.urlList.forEach((item) => {
      item.url += this.token;
    });
    this.userInfo = Vue.ls.get("Login_Userinfo");
    // this.getEnterpriseInfo();
    // this.getPay();
    // this.getWallet();
    // this.getInvoice();
    // this.recharge();
    // this.tradeCount();
    // this.notice();
  },
  mounted() {
    // this.getLineChart();
  },
  methods: {
    enter(){
      window.localStorage.setItem("submenu", 'raskManage');
      this.$router.push('/raskManage');
    },
    ggMenu(item) {
      let menu = item.split("?")[0].split("/");
      let submenu = menu[menu.length - 1];
      window.localStorage.setItem("submenu", 'raskManage');
    },
    // 获取曲线图数据
    getLineChart() {
      getLineChart().then((res) => {
        if (res.success) {
          let date = [];
          let y1 = [];
          let y2 = [];
          res.result.forEach((item) => {
            item.payAmount = item.payAmount === null ? 0 : item.payAmount;
            item.rechargeAmount =
              item.rechargeAmount === null ? 0 : item.rechargeAmount;
            date.push(item.date);
            y1.push(item.payAmount);
            y2.push(item.rechargeAmount);
          });
          this.drawLine(date, y1, y2);
        }
      });
    },
    // 查看公告
    showGk(item) {
      this.visiblegk = true;
      this.content = item;
    },
    // 获取系统消息
    notice() {
      notice({ pageNo: 1, pageSize: 10 }).then((res) => {
        if (res.success) {
          this.noticeLists = res.result.records;
        }
      });
    },
    // 查看全部
    checkAll() {
      notice({ pageNo: 1, pageSize: 100000 }).then((res) => {
        if (res.success) {
          this.noticeLists = res.result.records;
        }
      });
    },
    // 支付笔数
    tradeCount() {
      tradeCount().then((res) => {
        if (res.success) {
          this.tradeInfo = res.result;
        }
      });
    },
    // 充值金额
    recharge() {
      recharge().then((res) => {
        if (res.success) {
          this.rechargeInfo = res.result;
        }
      });
    },
    // 充值
    charge() {
      this.visiblehk = true;
      this.getBanknum();
    },
    // 获取银行卡号
    getBanknum() {
      getBanknum().then((res) => {
        if (res.success) {
          this.bankInfo = res.result;
        }
      });
    },
    // 获取待开票金额
    getInvoice() {
      getInvoiceTradesAmount().then((res) => {
        if (res.success) {
          this.awaitPay = res.result;
        }
      });
    },
    // 获取企业信息
    getEnterpriseInfo() {
      getEnterpriseReserveInfo().then((res) => {
        if (res.success) {
          this.enterpriseInfo = res.result;
        }
      });
    },
    // 获取支付金额、付款笔数
    getPay() {
      getPayStatistics().then((res) => {
        if (res.success) {
          this.payInfo = res.result;
        }
      });
    },
    // 获取总金额
    getWallet() {
      getWalletByType().then((res) => {
        if (res.success) {
          this.wallet = res.result;
          this.wallet.total = (
            res.result.frozenBalanceAmount + res.result.normalBalanceAmount
          ).toFixed(2);
        }
      });
    },
    drawLine(date, y1, y2) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart"));
      // 绘制图表
      let color = ["#8B5CFF", "#00CA69"];

      let xAxisData = date;
      let yAxisData1 = y1;
      let yAxisData2 = y2;

      const hexToRgba = (hex, opacity) => {
        let rgbaColor = "";
        let reg = /^#[\da-f]{6}$/i;
        if (reg.test(hex)) {
          rgbaColor = `rgba(${parseInt("0x" + hex.slice(1, 3))},${parseInt(
            "0x" + hex.slice(3, 5)
          )},${parseInt("0x" + hex.slice(5, 7))},${opacity})`;
        }
        return rgbaColor;
      };

      let option = {
        backgroundColor: "#fff",
        color: color,
        legend: {
          top: 20,
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            let html = "";
            params.forEach((v) => {
              html += `<div style="color: #666;font-size: 14px;line-height: 24px">
                      <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                        color[v.componentIndex]
                      };"></span>
                      ${v.seriesName}${v.name}  
                      <span style="color:${
                        color[v.componentIndex]
                      };font-weight:700;font-size: 18px;margin-left:5px">${
                v.value
              }</span>
                      元`;
            });
            return html;
          },
          extraCssText:
            "background: #fff; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;",
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "#ffffff",
              shadowColor: "rgba(225,225,225,1)",
              shadowBlur: 5,
            },
          },
        },
        grid: {
          top: 100,
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#333",
              },
            },
            axisLine: {
              lineStyle: {
                color: "#D9D9D9",
              },
            },
            data: xAxisData,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位（元）",
            axisLabel: {
              textStyle: {
                color: "#666",
              },
            },
            nameTextStyle: {
              color: "#666",
              fontSize: 12,
              lineHeight: 40,
            },
            // 分割线
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "#E9E9E9",
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            // name: "2018",
            name: "支付总金额",
            type: "line",
            smooth: true,
            symbolSize: 8,
            zlevel: 3,
            lineStyle: {
              normal: {
                color: color[0],
                shadowBlur: 3,
                shadowColor: hexToRgba(color[0], 0.5),
                shadowOffsetY: 8,
              },
            },
            symbol: "circle", //数据交叉点样式
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: hexToRgba(color[0], 0.3),
                    },
                    {
                      offset: 1,
                      color: hexToRgba(color[0], 0.1),
                    },
                  ],
                  false
                ),
                shadowColor: hexToRgba(color[0], 0.1),
                shadowBlur: 10,
              },
            },
            data: yAxisData1,
          },
          {
            name: "充值总金额",
            type: "line",
            smooth: true,
            symbolSize: 8,
            zlevel: 3,
            lineStyle: {
              normal: {
                color: color[1],
                shadowBlur: 3,
                shadowColor: hexToRgba(color[1], 0.5),
                shadowOffsetY: 8,
              },
            },
            symbol: "circle", //数据交叉点样式 (实心点)
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: hexToRgba(color[1], 0.3),
                    },
                    {
                      offset: 1,
                      color: hexToRgba(color[1], 0.1),
                    },
                  ],
                  false
                ),
                shadowColor: hexToRgba(color[1], 0.1),
                shadowBlur: 10,
              },
            },
            data: yAxisData2,
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>
<style scoped lang="less">
.container_home {
  width: 100%;
  min-height: 969px;
  // height: 100%;
  background: url("../../assets/index_bg.png") no-repeat 0 0;
  background-size: cover;
  padding-top: 300px;
  box-sizing: border-box;
  .img {
    width: 500px;
    height: 45px;
    margin: 0px auto 60px;
    
  }
  .home_img {
    width: 100%;
    height: 100%;
    text-align: center;
    
  }
  .btn_box {
    text-align: center;
  }
  .btn_enter {
    display: inline-block;
    padding: 10px 47px;
    background: #347cf5;
    border-radius: 45px;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
  }
}
.mychart {
  width: 100%;
  overflow-x: overlay;
}
#myChart {
  width: 100%;
  height: 400px;
}
.header {
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 200px;
  background: #ffffff url(../../assets/home_bg.png) no-repeat;
  background-size: contain;
  .header-name {
    display: flex;
    align-items: center;
    padding: 35px 0 0 23px;
    width: 685px;
    .avator {
      width: 62px;
      height: 62px;
      border-radius: 50%;
      margin-right: 14px;
    }
    .regret {
      color: #333333;
      font-size: 20px;
    }
  }
}
.common {
  background: #ffffff;
  border: 1px solid #e3e4e6;
  border-radius: 4px;
}
.content {
  position: relative;
  top: -80px;
  padding: 0px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  // padding-bottom: 30px;
  .content-left {
    width: 66.3%;
    .cl-one {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .clo-left {
        width: 49.3%;
        height: 200px;
        background: #ffffff url(../../assets/home_ct.png) no-repeat top right;
        background-size: 50px 40px;
        padding: 28px 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        color: #737373;
        .extra {
          font-size: 24px;
          color: #347cf5;
          margin: 15px 0;
        }
        .clol-name {
          font-size: 14px;
          color: #333333;
        }
        .clol-ky {
          margin-bottom: 6px;
          .span {
            display: inline-block;
            width: 70px;
            margin-right: 10px;
          }
        }
      }
      .clo-right {
        width: 49.3%;
        height: 200px;
        padding: 28px 36px;
        .clor-name {
          font-size: 14px;
          color: #333333;
          margin-bottom: 19px;
        }
        .clor-ct {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .colr-blc {
            width: 164px;
            // height: 83px;
            background: #f7f9fa;
            border-radius: 2px;
            padding: 7px 24px;
            .clor-title {
              font-size: 14px;
              color: #737373;
              margin-bottom: 5px;
            }
            .clor-account {
              font-size: 24px;
              color: #333333;
              margin: 0;
            }
            .clo-red {
              color: #df1313;
            }
          }
        }
      }
    }
    .cl-two {
      margin: 19px 0;
      padding: 28px 36px 28px 7px;
      .h2 {
        font-size: 14px;
        color: #333333;
        padding-left: 29px;
        margin-bottom: 0;
      }
      .clt-ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        li {
          margin: 26px 0 0 29px;
          a {
            display: inline-block;
            width: 169px;
            background: #f4f6f8;
            border-radius: 2px;
            padding: 6px 7px;
            font-size: 14px;
            color: #707070;
          }
        }
      }
    }
    .cl-three {
      padding: 28px 36px;
      padding-bottom: 0;
      .h2 {
        font-size: 14px;
        color: #333333;
        margin-bottom: 0;
      }
    }
  }
  .content-right {
    width: 32.7%;
    .cr-one {
      padding: 28px 36px;
      .h2 {
        font-size: 14px;
        color: #333333;
      }
      .cro-ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        li {
          width: 260px;
          background: #f7f9fa;
          padding: 7px 24px;
          margin-top: 10px;
          .crou-title {
            color: #737373;
            font-size: 12px;
            margin-bottom: 20px;
          }
          .crou-count {
            font-size: 24px;
            color: #333333;
          }
        }
      }
    }
    .cr-two {
      padding: 28px 35px;
      margin: 19px 0 26px 0;
      display: flex;
      justify-content: space-between;
      // align-items: center;
      .crt-o {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          font-size: 12px;
          color: #707070;
          margin: 0 0 15px 0;
          &:first-child {
            font-size: 14px;
            color: #333333;
          }
        }
      }
      .line {
        width: 1px;
        height: 166px;
        border-left: 1px dashed #707070;
        opacity: 1;
      }
    }
    .cr-three {
      padding: 27px 24px;
      .crt-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-size: 14px;
        color: #333333;
        .crth-r {
          font-size: 12px;
          color: #347cf5;
          cursor: pointer;
        }
      }
      .crt-ul {
        padding: 0;
        margin: 0;
        list-style: none;
        max-height: 410px;
        overflow-y: overlay;
        li {
          margin: 15px 0 0 0;
          a {
            display: inline-block;
            font-size: 14px;
            color: #707070;
            width: 500px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            &:hover {
              color: #3479fc;
            }
          }
        }
      }
    }
  }
}
.hkxx-content {
  list-style: none;
  padding: 0;
  li {
    margin: 10px 0;
    .left {
      color: #000000;
    }
    .right {
      color: #999999;
    }
  }
}
.textarea {
  width: 100%;
  border: 0;
  outline: none;
}
</style>